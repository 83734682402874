import App from 'next/app'
import { appWithTranslation, withNamespaces } from '>/i18n'
import Head from 'next/head'
import { ApolloProvider } from '@apollo/client'
import * as Sentry from '@sentry/browser'
import moment from 'moment'
import get from 'lodash/get'

import Error from '>/pages/_error'
import withApolloClient from '~/hoc/withApolloClient'
import config, { DEFAULT_FESTIVAL } from '~/config'

import { ModalProvider } from '~/components/Modal/ModalContext'

import cssMain from '+/main.sass'
import cssRW from '+/festival-rw.sass'
import cssGW from '+/festival-gw.sass'
import cssFDW from '+/festival-fdw.sass'
import cssCC from '+/festival-cc.sass'
import cssBW from '+/festival-bw.sass'
import cssPW from '+/festival-pw.sass'

import {
  getStaticFilePath,
  hasWindow,
  supportsIntersectionObserver,
  getFestival,
  // processEnv,
} from '~/utils/helpers'

const UA_KEY = 'UA-67017900-1'

moment.locale('pl')

Sentry.init({
  dsn: config().sentryDsn,
  enabled: !!config().sentryDsn,
  environment: config().sentryEnv,
  maxBreadcrumbs: 50,
  debug: false,
  release: config().sentryRelease,
  sendDefaultPii: true,
  beforeSend (event, hint) {
    const error = hint.originalException
    // Modify fingerprint for graphQL errors
    if (error && Object.getOwnPropertyNames(error).includes('graphQLErrors')) {
      event.fingerprint = [
        '{{ default }}',
        '{{ transaction }}',
        String(error.message)
      ]
    }
    return event
  },
  // integrations: [new Sentry.BrowserTracing()],
  // tracePropagationTargets: ['localhost', /^https:\/\/.*\.rclub\.dev/, /^https:\/\/.*\.restaurantclub\.pl/, /^\//],
  // tracesSampler: _samplingContext => {
  //   switch (processEnv.NEXT_ENV) {
  //     case 'production':
  //       return 0.1
  //     default:
  //       return 1.0
  //   }
  // }
})

@withNamespaces('meta')
class MyApp extends App {

  componentWillMount () {
    if (hasWindow() && !supportsIntersectionObserver()) require('intersection-observer')
  }

  componentDidMount () {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .register('/_next/static/service-worker.js')
    }

    this.handleRouteChange()
    this.props.router.events.on('routeChangeComplete', this.handleRouteChange)
  }

  componentWillUnmount () {
    this.props.router.events.off('routeChangeComplete', this.handleRouteChange)
  }

  handleRouteChange = async () => {
    const { router } = this.props

    hasWindow() && window.gtag && window.gtag('config', UA_KEY, {
      'page_path': router.asPath
    })

    if (hasWindow() && typeof window.KlarnaOnsiteService !== 'undefined') {
      window.KlarnaOnsiteService.push({ eventName: 'refresh-placements' })
    }
  }

  get festivalName () {
    const { router, domain } = this.props

    return getFestival(router, domain) || DEFAULT_FESTIVAL
  }

  get festival () {
    return config(this.festivalName).festival
  }

  get sentryTransaction () {
    const transaction = Sentry.getCurrentHub()
      .getScope()
      .getTransaction()

    if (transaction === undefined) {
      const transaction = Sentry.startTransaction({
        op: 'transaction',
        name: 'App'
      })
      Sentry.configureScope(scope => {
        scope.setSpan(transaction)
      })
    }

    return transaction
  }

  get sentryTraceId () {
    const transaction = this.sentryTransaction
    if (transaction) {
      return transaction.toTraceparent()
    }

    return ''
  }

  get styles () {
    switch (get(this.festival, 'id')) {
      case 'festival-rw':
        return cssRW
      case 'festival-gw':
        return cssGW
      case 'festival-fdw':
        return cssFDW
      case 'festival-cc':
        return cssCC
      case 'festival-bw':
        return cssBW
      case 'festival-pw':
        return cssPW
      default:
        return cssMain
    }
  }

  getFonts () {
    const fonts = [
      { href: getStaticFilePath('fonts/SofiaPro-Regular.woff'), type: 'font/woff' },
      { href: getStaticFilePath('fonts/SofiaPro-Semibold.woff'), type: 'font/woff' },
      { href: getStaticFilePath('fonts/PPFormula-CondensedBold.woff'), type: 'font/woff' },
      { href: getStaticFilePath('fonts/TCCCUnity-Regular.woff'), type: 'font/woff' },
      { href: getStaticFilePath('fonts/TCCCUnity-Bold.woff'), type: 'font/woff' },
    ]

    return (
      fonts.map(font => <link
        key={font.href}
        href={font.href}
        type={font.type}
        rel='preload' as='font' crossOrigin='anonymous'
      />)
    )
  }

  render () {
    const { Component, pageProps, apolloClient, apolloState = null, t } = this.props
    // Workaround for https://github.com/zeit/next.js/issues/8592
    const { err } = this.props
    const modifiedPageProps = { ...pageProps, err }

    if (get(pageProps, 'err.statusCode') === 404) {
      return <Error statusCode={pageProps.err.statusCode} />
    }

    return (
      <>
        <Head>
          <title>{t('app.title')}</title>
          <meta name='viewport' content='width=device-width, initial-scale=1.0' />
          <meta name='description' content={t('app.description')} />
          <meta property='og:image' content='https://restaurantclub.pl/static/assets/rc_share.png' key='image' />
          <meta property='og:title' content={t('app.title')} key='title' />
          <meta property='og:description' content={t('app.description')} key='description' />
          <meta property='og:type' content='website' />
          <meta property='og:site_name' content={t('app.title')} />
          <link rel='shortcut icon' href={getStaticFilePath('favicon-rc.png')} />
          {this.getFonts()}
          <link rel='stylesheet' href={this.styles} />
          <meta name='apple-itunes-app' content='app-id=1490565530, affiliate-data=restaurantclub' />
          <meta name='facebook-domain-verification' content='h3qy4e8vlegdngh8aa9l4f60dewdj2' />
          <meta name='sentry-trace' content={this.sentryTraceId} />
          {/* Works on stg, demo and prod */}
          {/* Google Tag Manager */}
          {config().enableAnalytics
            && <script
              async
              dangerouslySetInnerHTML={{
                __html: `
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','${config().gtm_key}');
                `
              }}
            />
          }
          {/* Google Tag Manager (noscript) */}
          {config().enableAnalytics && <noscript>
            <iframe
              src={`https://www.googletagmanager.com/ns.html?id=${config().gtm_key}`}
              height='0'
              width='0'
              style={{ display: 'none', visibility: 'hidden' }}
            />
          </noscript>}
          <script
            defer
            dangerouslySetInnerHTML={{
              __html: `window.__APOLLO_STATE__=${JSON.stringify(apolloState).replace(/</g, '\\u003c')};`,
            }}
          />
          {hasWindow() && config().klarna.client_id &&
          <script
            defer
            async
            src={config().klarna.sdk}
            data-client-id={config().klarna.client_id} />
          }
          {config().enableFocusChat
            && <script
              async
              dangerouslySetInnerHTML={{
                __html: `
                (function() { var s = document.createElement("script"),e = document.getElementsByTagName("script")[0];
                s.type = "text/javascript";s.async = true;s.src = "//tchat.fcc-online.pl/chat/widget.min.js";
                s.setAttribute('data-wid', 'hda3jnupjx9c0h1160yuvw2c39'); s.setAttribute('languageAutodetect', 'true');
                s.setAttribute('language', 'pl');e.parentNode.insertBefore(s, e);})();
                `
              }}
            />
          }
        </Head>
        <ApolloProvider client={apolloClient}>
          <ModalProvider>
            <Component {...modifiedPageProps} />
          </ModalProvider>
        </ApolloProvider>
      </>
    )
  }
}

export default withApolloClient(appWithTranslation(MyApp))
